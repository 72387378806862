import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Diese Seite existiert nicht." />
    <section className="notfound">
      <h3>Ein Fehler ist aufgetreten! (404)</h3>
      <p>Diese Seite existiert nicht.</p>
      <Link to="/">Zurück</Link>
    </section>
  </Layout>
)

export default NotFoundPage
